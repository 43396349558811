<template>
  <div class="signup">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'SignupView',
}
</script>

<style lang="scss">

</style>